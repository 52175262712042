import { BigNumber } from 'ethers';
import { ReactComponent as FortIcon } from 'assets/images/farm/xfort.svg';
import { ReactComponent as XsgdIcon } from 'assets/images/farm/xsgd.svg';
import { ReactComponent as UsdcIcon } from 'assets/images/farm/usdc.svg';

export const MAINNET = 'mainnet';
export const RINKEBY = 'rinkeby';

export const CHAIN_IDS = {
  [MAINNET]: 1,
  [RINKEBY]: 4,
};

export const DECIMAL_18 = BigNumber.from(10).pow(18);

export const FORT_TOKEN = {
  [CHAIN_IDS[MAINNET]]: '0x384f6e0C98dE722e4163e81F286335b96Aa8B178'.toLocaleLowerCase(),
  [CHAIN_IDS[RINKEBY]]:
    '0xABF0Daf7E2656145CF3bf6A566B3E5eF00d33c08'.toLocaleLowerCase(),
};

export const FORT_FACTORY = {
  [CHAIN_IDS[MAINNET]]: '0x38525f3b54Cc61a5F06E223B2FAf3878508f1Aa3'.toLocaleLowerCase(),
  [CHAIN_IDS[RINKEBY]]:
    '0x1f69D05D62Dab47f0Eb47B328500F16DEDdc3a36'.toLocaleLowerCase(),
};

export const BAR_TOKEN = {
  [CHAIN_IDS[MAINNET]]: '0x68f9E8BEe5d4644e7Ccb9DF4835cE9Af60d68284'.toLocaleLowerCase(),
  [CHAIN_IDS[RINKEBY]]:
    '0x5057365AB0864C4C60F5ed7D9a131628E78e4273'.toLocaleLowerCase(),
};

export const POOL_LIST = {
  [CHAIN_IDS[MAINNET]]: [
    {
      id: 0,
      name: 'xFORT',
      symbol: 'FORT',
      logo: <FortIcon style={{ width: 15, height: 15, color: '#fff' }} />,
      contractAddress:
        '0x384f6e0C98dE722e4163e81F286335b96Aa8B178'.toLocaleLowerCase(),
      tokenDecimals: 18,
      depositDetail: `
        Deposit FORT into xFORT.
        By staking in the xFORT single-sided pool, you’re able to generate passive yields just by holding FORT.
        The yield comes from the block rewards; 70% of which has been allocated to the xFORT single-sided staking pool.
        This makes the xFORT staking pool the most profitable pool to allocate your FORT to.
        Staking in the xFORT pool also entitles you to revenue generated by our future projects that we have lined up after the complete minting of FORT.
        <br/>
        <i>
          Important: By staking your FORT in the xFORT pool, the FORT will be locked up until the complete minting of 21 million FORT.
          20% of the staking rewards can be redeemed in the interim.
          Upon the complete minting of the 21 million FORT, you’ll be able to unstake your FORT from the xFORT pool and redeem your remaining rewards (80%), rewards will be released on a 2% per week schedule.
        </i>
      `,
      withdrawalDetail: `
        Unstake your FORT from the xFORT single-sided staking pool.
        Note that you’re only able to redeem 20% of the rewards earned.
        You can unstake your FORT from the xFORT pool once all 21 million FORT has been minted, which will then allow you to redeem the remaining 80% of rewards.
      `,
      allocationRate: 0.7,
    },
    {
      id: 1,
      name: 'XSGD',
      symbol: 'XSGD',
      logo: <XsgdIcon style={{ width: 15, height: 15 }} />,
      contractAddress:
        '0x70e8de73ce538da2beed35d14187f6959a8eca96'.toLocaleLowerCase(),
      tokenDecimals: 6,
      depositDetail: `
        Deposit XSGD into the single-sided staking pool.
        By staking XSGD in the single-sided pool, you’re able to generate passive yields.
        The yield comes from the block rewards: 1% of which has been allocated to the XSGD single-sided staking pool.
        Staking XSGD in the single-sided staking pool also entitles you to revenue generated by our future projects that we have lined up after the complete minting of FORT.
      `,
      withdrawalDetail: `
        Unstake your XSGD from the XSGD single-sided staking pool.
        Upon unstaking, you will not be able to receive yields from the block rewards.
      `,
      allocationRate: 0.01,
    },
  ],
  [CHAIN_IDS[RINKEBY]]: [
    {
      id: 0,
      name: 'xFORT',
      symbol: 'FORT',
      logo: <FortIcon style={{ width: 15, height: 15, color: '#fff' }} />,
      contractAddress:
        '0xABF0Daf7E2656145CF3bf6A566B3E5eF00d33c08'.toLocaleLowerCase(),
      tokenDecimals: 18,
      depositDetail: `
        Deposit FORT into xFORT.
        By staking in the xFORT single-sided pool, you’re able to generate passive yields just by holding FORT.
        The yield comes from the block rewards; 70% of which has been allocated to the xFORT single-sided staking pool.
        This makes the xFORT staking pool the most profitable pool to allocate your FORT to.
        Staking in the xFORT pool also entitles you to revenue generated by our future projects that we have lined up after the complete minting of FORT.
        <br/>
        <i>
          Important: By staking your FORT in the xFORT pool, the FORT will be locked up until the complete minting of 6 million FORT.
          20% of the staking rewards can be redeemed in the interim.
          Upon the complete minting of the 6 million FORT, you’ll be able to unstake your FORT from the xFORT pool and redeem your remaining rewards (80%), rewards will be released on a 2% per week schedule.
        </i>
      `,
      withdrawalDetail: `
        Unstake your FORT from the xFORT single-sided staking pool.
        Note that you’re only able to redeem 20% of the rewards earned.
        You can unstake your FORT from the xFORT pool once all 6 million FORT has been minted, which will then allow you to redeem the remaining 80% of rewards.
      `,
      allocationRate: 0.7,
    },
    {
      id: 1,
      name: 'XSGD',
      symbol: 'XSGD',
      logo: <XsgdIcon style={{ width: 15, height: 15 }} />,
      contractAddress:
        '0x502f9411308235c6e36351240059866DeB901ed5'.toLocaleLowerCase(),
      tokenDecimals: 18,
      depositDetail: `
        Deposit XSGD into the single-sided staking pool.
        By staking XSGD in the single-sided pool, you’re able to generate passive yields.
        The yield comes from the block rewards: 1% of which has been allocated to the XSGD single-sided staking pool.
        Staking XSGD in the single-sided staking pool also entitles you to revenue generated by our future projects that we have lined up after the complete minting of FORT.
      `,
      withdrawalDetail: `
        Unstake your XSGD from the XSGD single-sided staking pool.
        Upon unstaking, you will not be able to receive yields from the block rewards.
      `,
      allocationRate: 0.01,
    },
  ],
};

export const FARM_LIST = {
  [CHAIN_IDS[MAINNET]]: [
    {
      id: 2,
      name: 'FORT-XSGD',
      token0: {
        name: 'FORT',
        symbol: 'FORT',
        contractAddress: '0x384f6e0C98dE722e4163e81F286335b96Aa8B178'.toLowerCase(),
        logo: <FortIcon style={{ fontSize: 30 }} />,
      },
      token1: {
        name: 'XSGD',
        symbol: 'XSGD',
        contractAddress: '0x70e8de73ce538da2beed35d14187f6959a8eca96'.toLowerCase(),
        logo: <XsgdIcon />,
      },
      contractAddress:
        '0x83bb779bf79f418d296839099b699f95773aae06'.toLocaleLowerCase(),
      rewardDetail: `
        You are able to generate passive yields from the block rewards: 15% of which has been allocated to the FORT-XSGD liquidity pool.
        When you provide FORT-XSGD liquidity, it entitles you to revenue generated by our future projects that we have lined up after the complete minting of FORT.
      `,
      stakeDetail: `
        Provide FORT-XSGD liquidity.
        By providing liquidity into the FORT-XSGD pool, unique tokens known as liquidity tokens are minted and sent to the provider's address.
        These tokens represent a given liquidity provider's contribution to a pool.
        The proportion of the pool's liquidity provided determines the number of liquidity tokens the provider receives.
      `,
      allocationRate: 0.15,
    },
    {
      id: 3,
      name: 'FORT-USDC',
      token0: {
        name: 'FORT',
        symbol: 'FORT',
        contractAddress: '0x384f6e0C98dE722e4163e81F286335b96Aa8B178'.toLowerCase(),
        logo: <FortIcon style={{ fontSize: 30 }} />,
      },
      token1: {
        name: 'USDC',
        symbol: 'USDC',
        contractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'.toLowerCase(),
        logo: <UsdcIcon />,
      },
      contractAddress:
        '0xed250c8db681a41051d9c408350651cba17428d2'.toLocaleLowerCase(),
      rewardDetail: `
        You are able to generate passive yields from the block rewards: 14% of which has been allocated to the FORT-USDC liquidity pool.
        When you provide FORT-USDC liquidity, it entitles you to revenue generated by our future projects that we have lined up after the complete minting of FORT.
      `,
      stakeDetail: `
        Provide FORT-USDC liquidity.
        By providing liquidity into the FORT-USDC pool, unique tokens known as liquidity tokens are minted and sent to the provider's address.
        These tokens represent a given liquidity provider's contribution to a pool.
        The proportion of the pool's liquidity provided determines the number of liquidity tokens the provider receives.
      `,
      allocationRate: 0.14,
    },
  ],
  [CHAIN_IDS[RINKEBY]]: [
    {
      id: 2,
      name: 'FORT-XSGD',
      token0: {
        name: 'FORT',
        symbol: 'FORT',
        contractAddress: '0xABF0Daf7E2656145CF3bf6A566B3E5eF00d33c08'.toLowerCase(),
        logo: <FortIcon style={{ fontSize: 30 }} />,
      },
      token1: {
        name: 'XSGD',
        symbol: 'XSGD',
        contractAddress: '0x502f9411308235c6e36351240059866DeB901ed5'.toLowerCase(),
        logo: <XsgdIcon />,
      },
      contractAddress:
        '0x642cEa4259FC75D4f22a3153cEE446eF1a6A0bbc'.toLocaleLowerCase(),
      rewardDetail: `
        You are able to generate passive yields from the block rewards: 15% of which has been allocated to the FORT-XSGD liquidity pool.
        When you provide FORT-XSGD liquidity, it entitles you to revenue generated by our future projects that we have lined up after the complete minting of FORT.
      `,
      stakeDetail: `
        Provide FORT-XSGD liquidity.
        By providing liquidity into the FORT-XSGD pool, unique tokens known as liquidity tokens are minted and sent to the provider's address.
        These tokens represent a given liquidity provider's contribution to a pool.
        The proportion of the pool's liquidity provided determines the number of liquidity tokens the provider receives.
      `,
      allocationRate: 0.15,
    },
    {
      id: 3,
      name: 'FORT-USDC',
      token0: {
        name: 'FORT',
        symbol: 'FORT',
        contractAddress: '0xABF0Daf7E2656145CF3bf6A566B3E5eF00d33c08'.toLowerCase(),
        logo: <FortIcon style={{ fontSize: 30 }} />,
      },
      token1: {
        name: 'USDC',
        symbol: 'USDC',
        contractAddress: '0x90B954Be83a395Ce03b828c6E82710d8B95f2d92'.toLowerCase(),
        logo: <UsdcIcon />,
      },
      contractAddress:
        '0x983096407dE864dea09F85be78C64B6669DE7595'.toLocaleLowerCase(),
      rewardDetail: `
        You are able to generate passive yields from the block rewards: 14% of which has been allocated to the FORT-USDC liquidity pool.
        When you provide FORT-USDC liquidity, it entitles you to revenue generated by our future projects that we have lined up after the complete minting of FORT.
      `,
      stakeDetail: `
        Provide FORT-USDC liquidity.
        By providing liquidity into the FORT-USDC pool, unique tokens known as liquidity tokens are minted and sent to the provider's address.
        These tokens represent a given liquidity provider's contribution to a pool.
        The proportion of the pool's liquidity provided determines the number of liquidity tokens the provider receives.
      `,
      allocationRate: 0.14,
    },
  ],
};

export const CONTRACT_LINK = {
  [CHAIN_IDS[MAINNET]]: `https://etherscan.io/address/${FORT_TOKEN[CHAIN_IDS[MAINNET]]}`,
  [CHAIN_IDS[RINKEBY]]: `https://rinkeby.etherscan.io/address/${FORT_TOKEN[CHAIN_IDS[RINKEBY]]}`,
}
